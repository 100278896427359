'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

const PageConfigContext = createContext();

const PageConfigProvider = (props) => {
    const { children, state } = props;

    const contextValue = useMemo(() => [state], [state]);

    return <PageConfigContext.Provider value={contextValue}>{children}</PageConfigContext.Provider>;
};

const mapStateToProps = ({ pageConfig }) => ({ pageConfigState: pageConfig });

export default connect(mapStateToProps)(PageConfigProvider);

export const usePageConfigContext = () => useContext(PageConfigContext);
