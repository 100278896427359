import { useCallback } from 'react';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useSignIn = () => {
    const [{ isEnabled }] = useGtmContext();

    const submitSignInToDataLayer = useCallback(
        ({ token }) => {
            if (isEnabled && token) {
                actionsTagManager.login();
            }
        },
        [isEnabled],
    );

    return {
        submitSignInToDataLayer,
    };
};
