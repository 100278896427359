'use client';

import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { getFacebookFullDataMutation } from '@/integrations/google-tag-manager/src/talons/tagManager.gql';
import { useCartContext } from '@/lib/context';
import { useGtmContext } from './gtm';
import { arrayToObject } from '@/integrations/google-tag-manager/src/helpers/utils';
import actionsTagManager from '@/integrations/google-tag-manager/src/helpers/actions';

interface IFacebookUserDataContext {
    facebookPixelUserData?: Array<{ key: string; value: string }>;
    setPurchaseData: () => void;
}

interface IFacebookUserDataContextProvider {
    children: ReactNode;
}

const FacebookUserDataContext = createContext<IFacebookUserDataContext>({} as IFacebookUserDataContext);

const deepEqual = (obj1: Record<any, any> | null, obj2: Record<any, any> | null) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const FacebookUserDataContextProvider = ({ children }: IFacebookUserDataContextProvider) => {
    const [{ isEnabled }] = useGtmContext();
    const [{ cartId }] = useCartContext();
    const prevUserDataRef = useRef<Record<any, any> | null>(null);

    const [runMutation, { data: userData }] = useMutation(getFacebookFullDataMutation, {
        fetchPolicy: 'no-cache',
    });

    const input = useMemo(() => {
        if (!(isEnabled && cartId)) return;

        return cartId;
    }, [isEnabled, !!cartId]);

    const executePipe = async (payload?: string) => {
        if (!(payload && isEnabled)) return;

        const { data } = await runMutation({
            variables: { input: payload },
        });

        const userData = data.facebookPixelFullUserData;

        const fb_user_data = userData?.length ? arrayToObject(userData) : null;

        if (fb_user_data) {
            if (!deepEqual(fb_user_data, prevUserDataRef.current)) {
                actionsTagManager.faceBookUserData({ fb_user_data });
                prevUserDataRef.current = fb_user_data;
            }
        }
    };

    const setPurchaseData = () => {
        executePipe('purchase');
    };

    useEffect(() => {
        executePipe(input);
    }, [input]);

    return (
        <FacebookUserDataContext.Provider
            value={{ facebookPixelUserData: userData?.facebookPixelFullUserData, setPurchaseData }}
        >
            {children}
        </FacebookUserDataContext.Provider>
    );
};

export const useFacebookUserDataContext = () => useContext(FacebookUserDataContext);
