'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import actions from '@/lib/store/actions/checkout/actions';
import bindActionCreators from '@/lib/store/utils/bindActionCreators';

const CheckoutContext = createContext();

const CheckoutContextProvider = (props) => {
    const { actions, checkoutState, children, initState } = props;

    const contextValue = useMemo(
        () => [
            { ...initState, ...checkoutState },
            {
                actions,
            },
        ],
        [actions, checkoutState, initState],
    );

    return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>;
};

const mapStateToProps = ({ checkout }) => ({ checkoutState: checkout });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContextProvider);

export const useCheckoutContext = () => useContext(CheckoutContext);
