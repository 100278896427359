import { useEffect } from 'react';
import { mapCoupons, mapProductForCheckoutEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';
import { usePageType } from './usePageType';

export const usePurchase = ({ orderDetailsData, orderNumber, websiteName, ...restData }) => {
    const condition = usePageType({ ...restData, isComparePathname: false });
    const [{ isEnabled }] = useGtmContext();

    const shippingData = ({ firstname, lastname, selected_shipping_method }) => ({
        firstName: firstname,
        lastName: lastname,
        shipping: +(
            selected_shipping_method.amount.value - (selected_shipping_method.shipping_discount_amount.value || 0)
        ).toFixed(2),
    });

    const taxes = orderDetailsData?.cart?.prices?.applied_taxes?.reduce((total, tax) => total + tax.amount.value, 0);

    useEffect(() => {
        if (condition || !isEnabled || !orderDetailsData || !orderNumber) return;

        actionsTagManager.resetEcommerce();
        actionsTagManager.purchase({
            purchase: {
                affiliation: websiteName,
                ...(orderDetailsData.cart.applied_coupons?.length && {
                    coupon: mapCoupons({ appliedCoupons: orderDetailsData.cart.applied_coupons }),
                }),
                currency: orderDetailsData.cart.prices.grand_total.currency,
                email: orderDetailsData.cart.email,
                items: orderDetailsData.cart.items.map(mapProductForCheckoutEvents),
                ...shippingData(orderDetailsData.cart.shipping_addresses[0]),
                [`${orderDetailsData?.cart?.prices?.base_currency_revenue?.currency || ''}revenue`]:
                    orderDetailsData.cart.prices.base_currency_revenue.value,
                tax: taxes,
                total: orderDetailsData.cart.prices.grand_total.value,
                transaction_id: orderNumber,
                value: +(
                    orderDetailsData.cart.prices.grand_total.value -
                    (orderDetailsData.cart.shipping_addresses[0].selected_shipping_method.amount.value -
                        (orderDetailsData.cart.shipping_addresses[0].selected_shipping_method.shipping_discount_amount
                            .value || 0)) -
                    taxes
                ).toFixed(2),
            },
        });
    }, [condition, isEnabled, orderDetailsData, orderNumber, taxes, websiteName]);
};
