import { useCallback } from 'react';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useSignUp = () => {
    const [{ isEnabled }] = useGtmContext();

    const submitSignUpToDataLayer = useCallback(() => {
        if (!isEnabled) return;

        return actionsTagManager.signUp();
    }, [isEnabled]);

    return { submitSignUpToDataLayer };
};
