import { useEffect } from 'react';
import { mapPromotionsData } from '../helpers/flattenPromotionsData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useViewPromotion = ({ promotions }) => {
    const [{ isEnabled }] = useGtmContext();

    useEffect(() => {
        if (!isEnabled || !promotions?.length) return;

        const filteredPromotions = promotions.filter((promotion) => promotion.includes('promo_id'));

        actionsTagManager.viewPromotion({
            items: mapPromotionsData({ promotions: filteredPromotions }),
        });
    }, [isEnabled, JSON.stringify(promotions)]);
};
