import { createActions } from 'redux-actions';

const prefix = 'CHECKOUT';
const actionTypes = [
    'BEGIN',
    'EDIT',
    'RESET',
    'SET_GUEST_CUSTOMER_EMAIL',
    'SET_IS_INVOICE_REQUESTED',
    'SET_IS_NEWSLETTER_CHECKED',
    'SET_IS_CUSTOMER_EMAIL_NEW',
    'SET_IS_CUSTOMER_SUBSCRIBED',
    'SET_ALLOWED_COUNTRIES_FOR_CHECKOUT',
    'SET_IS_NEW_CUSTOMER_ADDRESS',
    'SET_IS_REDIRECT_TO_PAYMENT_SERVICE',
    'SET_IS_ORDER_PROCESSING',
];

// classify action creators by domain
// e.g., `actions.order.submit` => CHECKOUT/ORDER/SUBMIT
// a `null` value corresponds to the default creator function
const actionMap = {
    BILLING_ADDRESS: {
        ACCEPT: null,
        REJECT: null,
        SUBMIT: null,
    },
    GET_SHIPPING_METHODS: {
        RECEIVE: null,
        REQUEST: null,
    },
    ORDER: {
        ACCEPT: null,
        REJECT: null,
        SUBMIT: null,
    },
    PAYMENT_METHOD: {
        ACCEPT: null,
        REJECT: null,
        SUBMIT: null,
    },
    RECEIPT: {
        RESET: null,
        SET_ORDER: null,
    },
    SHIPPING_ADDRESS: {
        ACCEPT: null,
        REJECT: null,
        SUBMIT: null,
    },
    SHIPPING_METHOD: {
        ACCEPT: null,
        REJECT: null,
        SUBMIT: null,
    },
};

export default createActions(actionMap, ...actionTypes, { prefix });
