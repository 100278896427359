'use client';

import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import actions from '@/lib/store/actions/user/actions';
import * as asyncActions from '@/lib/store/actions/user/asyncActions';
import bindActionCreators from '@/lib/store/utils/bindActionCreators';
import { BrowserPersistence } from '@/utils';

import { USER_STORAGE } from '@/constants/storage';

const UserContext = createContext();

const UserContextProvider = (props) => {
    const { actions, asyncActions, children, userState } = props;

    const userApi = useMemo(
        () => ({
            actions,
            ...asyncActions,
        }),
        [actions, asyncActions],
    );

    const contextValue = useMemo(() => [userState, userApi], [userApi, userState]);

    useEffect(() => {
        // check if the user's token is not expired
        const storage = new BrowserPersistence();
        const signInToken = storage.getRawItem(USER_STORAGE.SIGNIN_TOKEN);
        // Depreciated auth validation. It can be removed after full migration to Next.js
        const hasSessionData = storage.getItem(USER_STORAGE.HAS_SESSION_DATA);

        if (signInToken) {
            const { timeStored, ttl, value } = JSON.parse(signInToken);
            const now = Date.now();

            // if the token's TTL has expired, we need to sign out
            if (ttl && now - timeStored > ttl * 1000) {
                asyncActions.signOut();
            } else {
                actions.setToken(JSON.parse(value));
            }
        } else if (hasSessionData) {
            actions.setToken(undefined);
        } else {
            actions.setIsGuest();
        }
    }, [asyncActions, actions]);

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

const mapStateToProps = ({ user }) => ({ userState: user });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    asyncActions: bindActionCreators(asyncActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserContextProvider);

export const useUserContext = () => useContext(UserContext);
