import { createActions } from 'redux-actions';

const prefix = 'APP';
const actionTypes = [
    'TOGGLE_DRAWER',
    'SET_ADD_TO_CART_SUCCESS_DATA',
    'SET_ADD_TO_CART_ACTION_STATUS',
    'SET_IS_FILTER_MODAL_OPEN',
    'SET_IS_FILTER_SIDEBAR_OPEN',
    'SET_IS_SEARCH_OPEN',
    'SET_IS_STORE_SWITCHER_OPEN',
    'SET_SHOULD_PURGE_PREVIOUS_ITEMS',
    'SET_ONLINE',
    'SET_OFFLINE',
    'TOGGLE_SEARCH',
    'EXECUTE_SEARCH',
    'MARK_ERROR_HANDLED',
    'SET_PAGE_LOADING',
    'SET_LOADING_BAR_PROGRESS',
    'SET_NEXT_ROOT_COMPONENT',
];

export default createActions(...actionTypes, { prefix });
