import { useInitialize } from '../hooks';
import { useGtmContext } from '../context/gtm';

const DEFAULT_GTM_BASE_URL = 'https://www.googletagmanager.com';

export const useGTMInitialize = () => {
    const [{ auth, gtmId, isEnabled, preview, source }] = useGtmContext();

    useInitialize({ auth, gtmId, isEnabled, preview, source: source || DEFAULT_GTM_BASE_URL });
};
