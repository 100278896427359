import { useCallback } from 'react';
import {
    mapFredHopperValues,
    mapProductForSelectItemEvent,
} from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useSelectProduct = () => {
    const [{ isEnabled }] = useGtmContext();

    const selectItem = useCallback(
        ({ fredHopper, product }) => {
            if (!isEnabled || !product) return;

            actionsTagManager.resetEcommerce();

            return actionsTagManager.selectItem({
                fredHopper: mapFredHopperValues(fredHopper),
                items: mapProductForSelectItemEvent(product),
            });
        },
        [isEnabled],
    );

    return { selectItem };
};
