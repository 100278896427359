export const CART_STORAGE = { CART_ID: 'cartId' };

export const CATALOG_STORAGE = { IMAGES_BY_SKU: 'imagesBySku' };

export const CHECKOUT_STORAGE = {
    AVAILABLE_SHIPPING_METHODS: 'availableShippingMethods',
    BILLING_ADDRESS: 'billing_address',
    PAYMENT_METHOD: 'paymentMethod',
    SHIPPING_ADDRESS: 'shipping_address',
    SHIPPING_METHOD: 'shippingMethod',
};

export const USER_STORAGE = {
    HAS_SESSION_DATA: 'has_session_data',
    SIGNIN_TOKEN: 'signin_token',
};

export const STORE_VIEW_STORAGE = {
    STORE_VIEW_CODE: 'store_view_code',
    STORE_VIEW_CURRENCY: 'store_view_currency',
};

export const STORE_SWITCHER = {
    SHOULD_SHOW_STORE_TOAST: 'should-show-store-toast',
};
