import Snippets from './snippets';

const DEFAULT_DATA_LAYER_NAME = 'dataLayer';
const DATA_GTM_NO_SCRIPT = 'gtm-no-script';
const DATA_GTM_SCRIPT = 'gtm-script';

const TagManager = {
    dataLayer({ dataLayer, dataLayerName = DEFAULT_DATA_LAYER_NAME }) {
        if (window[dataLayerName]) return window[dataLayerName].push(dataLayer);
        const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
        const dataScript = this.dataScript(snippets);
        document.head.insertBefore(dataScript, document.head.childNodes[0]);
    },
    dataScript(dataLayer) {
        const script = document.createElement('script');
        script.innerHTML = dataLayer;

        return script;
    },
    gtm(args) {
        const snippets = Snippets.tags(args);

        const noScript = () => {
            const noscript = document.createElement('noscript');
            noscript.setAttribute('data-script-id', DATA_GTM_NO_SCRIPT);
            noscript.innerHTML = snippets.iframe;

            return noscript;
        };

        const script = () => {
            const script = document.createElement('script');
            script.setAttribute('data-script-id', DATA_GTM_SCRIPT);
            script.innerHTML = snippets.script;

            return script;
        };

        const dataScript = this.dataScript(snippets.dataLayerVar);

        return {
            dataScript,
            noScript,
            script,
        };
    },
    initialize({
        auth = '',
        dataLayer,
        dataLayerName = DEFAULT_DATA_LAYER_NAME,
        events = {},
        gtmId,
        preview = '',
        source,
    }) {
        const gtm = this.gtm({
            auth,
            dataLayer: dataLayer || undefined,
            dataLayerName,
            events,
            id: gtmId,
            preview,
            source,
        });
        if (dataLayer) document.head.appendChild(gtm.dataScript);

        if (!document.querySelector(`script[data-script-id="${DATA_GTM_SCRIPT}"]`)) {
            document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
        }

        if (!document.querySelector(`noscript[data-script-id="${DATA_GTM_NO_SCRIPT}"]`)) {
            document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
        }
    },
};

export default TagManager;
