import { useCallback } from 'react';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';

export const useFormSubmit = () => {
    const [{ isEnabled }] = useGtmContext();

    const submitFormToDataLayer = useCallback(
        ({ formName }) => {
            if (!isEnabled || !formName) return;

            return actionsTagManager.formSubmit({ formName });
        },
        [isEnabled],
    );

    return { submitFormToDataLayer };
};
