const DEFAULT_SOURCE = 'https://www.googletagmanager.com';

const Snippets = {
    dataLayer(dataLayer, dataLayerName) {
        return `
        window.${dataLayerName} = window.${dataLayerName} || [];
        window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
    },
    tags({ auth, dataLayer, dataLayerName, events, id, preview, source }) {
        const gtmSource = source || DEFAULT_SOURCE;
        const gtmAuth = auth ? `&gtm_auth=${auth}` : '';
        const gtmPreview = preview ? `&gtm_preview=${preview}` : '';
        const gtmCookiesWin = gtmAuth || gtmPreview ? '&gtm_cookies_win=x' : '';

        if (!id) {
            console.warn('GTM Id is required');
        }

        const iframe = `
        <iframe src="${gtmSource}/ns.html?id=${id}${gtmAuth}${gtmPreview}${gtmCookiesWin}"
          height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

        const script = `
        (function(w,d,s,l,i){w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(events).slice(1, -1)}});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='${gtmSource}/gtm.js?id='+i+dl+'${gtmAuth}${gtmPreview}${gtmCookiesWin}';
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','${dataLayerName}','${id}');`;

        const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

        return {
            dataLayerVar,
            iframe,
            script,
        };
    },
};

export default Snippets;
