import { useEffect } from 'react';
import { mapProductForViewItemEvent } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { usePageType } from './usePageType';

export const useViewProduct = ({ product, ...restData }) => {
    const condition = usePageType(restData);

    useEffect(() => {
        if (condition || !product?.sku) return;

        actionsTagManager.resetEcommerce();
        actionsTagManager.viewItem({
            items: mapProductForViewItemEvent(product),
        });

        sessionStorage.setItem('fauxVI', 'positive');
    }, [condition, product?.uid]);
};
