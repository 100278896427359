import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { cookieManager } from '@vaimo-int/one-trust';
import { getCustomerData } from '@vaimo/google-tag-manager/src/talons/tagManager.gql';
import { arrayToObject } from '../helpers/utils';
import { useFacebookUserDataContext } from '../context/facebookUserDataContext';

const ACCOUNT_TYPE_GENERAL = 'General';
const ACCOUNT_TYPE_GUEST = 'NOT LOGGED IN';
const ACCOUNT_TYPE_PRO = 'PRO Customers';
const COOKIE_GTM_USER_ID = 'vaimo-gtm-user-id';

interface IUseCustomer {
    isEnabled: boolean;
    isPurchaseStep: boolean;
    isSignedIn: boolean;
}

export const useCustomer = ({ isEnabled, isPurchaseStep, isSignedIn }: IUseCustomer) => {
    const [guestUserData, setGuestUserData] = useState<{ account_type: string } | null>(null);
    const [loginUserData, setLoginUserData] = useState<Record<any, any> | null>(null);

    const { data, error, loading } = useQuery(getCustomerData, {
        fetchPolicy: 'cache-and-network',
        skip: !isSignedIn,
    });

    const { facebookPixelUserData, setPurchaseData } = useFacebookUserDataContext();

    useEffect(() => {
        if (!isPurchaseStep) return;

        setPurchaseData();
    }, [isPurchaseStep]);

    useEffect(() => {
        if (!isSignedIn) {
            cookieManager.setCookie({
                groupId: cookieManager.PrivacyGroupEnum.TARGETING,
                name: COOKIE_GTM_USER_ID,
                timeToLiveInMinutes: 0,
                value: '',
            });
            setGuestUserData({ account_type: ACCOUNT_TYPE_GUEST });
        }
    }, [isSignedIn]);

    useEffect(() => {
        if (!isEnabled || !isSignedIn || loading || !data?.customer || error) {
            return;
        }

        // Set Vaimo GTM User ID cookie
        cookieManager.setCookie({
            groupId: cookieManager.PrivacyGroupEnum.TARGETING,
            name: COOKIE_GTM_USER_ID,
            value: data.customer?.customer_id || '',
        });

        const {
            customer: { pro_account },
        } = data;
        const { discount: proDiscount, discount_applicable: isActivePro } = pro_account || {};
        const fb_user_data = facebookPixelUserData?.length ? arrayToObject(facebookPixelUserData) : null;

        const proAccountData = isActivePro
            ? {
                  account_type: ACCOUNT_TYPE_PRO,
                  pro_account_discount: proDiscount,
                  pro_account_type: data.customer.pro_account?.classification?.title ?? false,
              }
            : {
                  account_type: ACCOUNT_TYPE_GENERAL,
                  pro_account_type: 'No Classification',
              };

        setLoginUserData({
            ...proAccountData,
            crm_id: data.customer?.customer_id || '', // Vaimo Cookie Until Possibly Replaced in Future
            fb_user_data,
            user_id: data.customer?.customer_id || '', // Vaimo Cookie
        });
    }, [isEnabled, isSignedIn, data?.customer]);

    return {
        guestUserData,
        loginUserData,
    };
};
