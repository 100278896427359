export const arrayToObject = (array) => array.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

export const getBrowserData = () => {
    const browser = {};
    const { userAgent } = navigator || { userAgent: '' };

    switch (true) {
        case userAgent.includes('Firefox/'):
            browser.name = 'Firefox';
            browser.version = userAgent.split('Firefox/')[1];
            break;

        case userAgent.includes('Edg/'):
            browser.name = 'Edge';
            browser.version = userAgent.split('Edg/')[1];
            break;

        case userAgent.includes('OPR/'):
            browser.name = 'Opera';
            browser.version = userAgent.split('OPR/')[1];
            break;

        case userAgent.includes('Chrome/'):
            browser.name = 'Chrome';
            browser.version = userAgent.split('Chrome/')[1]?.split(' ')[0] || 'Unknown';
            break;

        case userAgent.includes('Safari/'):
            browser.name = 'Safari';
            browser.version = userAgent.split('Version/')[1]?.split(' ')[0] || 'Unknown';
            break;

        default:
            browser.name = 'Unknown';
            browser.version = 'Unknown';
    }

    return browser;
};

const NA_COUNTRIES = ['ca', 'us'];
const GLOBAL = 'global';

export const getRegion = (code) => {
    if (NA_COUNTRIES.includes(code)) return 'NA';

    if (code === GLOBAL) return 'ROW';

    return 'Europe';
};
