export const PAGE_TYPES = {
    ACCOUNT_DASHBOARD: 'Inside Customer Account',
    CART: 'Cart Page',
    CATEGORY: 'Category Page',
    CHECKOUT: 'Checkout Page',
    CHECKOUT_SUCCESS: 'Success Page',
    CONTACT_US: 'Contact Page',
    CUSTOMERSERVICE: 'Customer Service Page',
    HOME: 'Home Page',
    NOT_FOUND: '404 Pages',
    PRODUCT: 'Product Detail Page',
    SEARCH_RESULTS: 'Search Results Page',
    SIGN_IN: 'Account Login',
    SIGN_OUT: 'Exited Customer Account',
    SIGN_UP: 'Account Creation Page',
    'STORE-FINDER': 'Store Finder',
    WISHLIST: 'Wishlist Page',
};
