import { createActions } from 'redux-actions';

const prefix = 'USER';
const actionTypes = ['RESET', 'SET_TOKEN', 'CLEAR_TOKEN', 'SET_IS_SIGNED_IN', 'SET_IS_GUEST', 'SET_IS_LOGIN_PROCESS'];

const actionMap = {
    CREATE_ACCOUNT: {
        RECEIVE: null,
        REQUEST: null,
    },
    GET_DETAILS: {
        RECEIVE: null,
        REQUEST: null,
    },
    RESET_PASSWORD: {
        RECEIVE: null,
        REQUEST: null,
    },
    SIGN_IN: {
        RECEIVE: null,
        REQUEST: null,
    },
};

export default createActions(actionMap, ...actionTypes, { prefix });
