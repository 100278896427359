import { useEffect } from 'react';
import { mapCoupons, mapProductForCheckoutEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';
import { usePageType } from './usePageType';

export const useBeginCheckout = ({ checkoutStep, orderDetailsData, ...restData }) => {
    const [{ isEnabled }] = useGtmContext();
    const condition = usePageType({ ...restData });

    useEffect(() => {
        if (condition || !isEnabled || !orderDetailsData?.cartItems?.length || checkoutStep > 1) {
            return;
        }

        actionsTagManager.resetEcommerce();
        actionsTagManager.beginCheckout({
            coupon: mapCoupons(orderDetailsData),
            items: orderDetailsData.cartItems.map(mapProductForCheckoutEvents),
        });
    }, [condition, checkoutStep, isEnabled, JSON.stringify(orderDetailsData)]);
};
