import { useCallback } from 'react';
import { mapPromotionsData } from '@vaimo/google-tag-manager/src/helpers/flattenPromotionsData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useSelectPromotion = () => {
    const [{ isEnabled }] = useGtmContext();

    const selectPromotion = useCallback(
        ({ promotion }) => {
            if (!isEnabled || !promotion?.includes('promo_id')) return;

            actionsTagManager.selectPromotion({
                items: mapPromotionsData({ promotions: [promotion] }),
            });

            // Checking if URL ends with 5 digit SKU and then the promo param string starts
            // That's the best that we can predict by looking at home page
            // Then we assume it is a product listing page and set storage value
            const isSingleProductPromo = promotion.match(/\d{5}\?/);
            !isSingleProductPromo && sessionStorage.setItem('intentViewItemList', 'positive');
        },
        [isEnabled],
    );

    return { selectPromotion };
};
