'use client';

import { useEffect } from 'react';
import {
    mapFredHopperValues,
    mapProductForViewItemListEvent,
} from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';
import { usePageType } from './usePageType';

export const useViewProductList = ({ categoryId, fredHopper, itemListName, items, ...restData }) => {
    const [{ isEnabled }] = useGtmContext();
    const condition = usePageType(restData);

    useEffect(() => {
        if (condition || !isEnabled || !Array.isArray(items) || !items.length) return;

        actionsTagManager.resetEcommerce();
        actionsTagManager.viewItemList({
            fredHopper: mapFredHopperValues(fredHopper),
            items: mapProductForViewItemListEvent({ categoryId, itemListName, items }),
        });

        if (sessionStorage.getItem('intentViewItemList')) {
            sessionStorage.removeItem('intentViewItemList');
        }
    }, [condition, isEnabled, items?.length]);
};
