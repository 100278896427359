import { useEffect } from 'react';
import { mapCoupons, mapProductForCheckoutEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useShippingAddressDetails = ({ checkoutStep, orderDetailsData }) => {
    const [{ isEnabled }] = useGtmContext();

    useEffect(() => {
        if (!isEnabled || !orderDetailsData?.cartItems?.length || checkoutStep !== 2) {
            return;
        }

        actionsTagManager.resetEcommerce();
        actionsTagManager.shippingAddressDetails({
            coupon: mapCoupons(orderDetailsData),
            items: orderDetailsData.cartItems.map(mapProductForCheckoutEvents),
        });
    }, [checkoutStep, isEnabled, JSON.stringify(orderDetailsData)]);
};
