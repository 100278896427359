import { createActions } from 'redux-actions';

const prefix = 'CART';

const actionMap = {
    ADD_ITEM: {
        RECEIVE: null,
        REQUEST: null,
    },
    GET_CART: {
        RECEIVE: null,
        REQUEST: null,
    },
    GET_DETAILS: {
        RECEIVE: null,
        REQUEST: null,
    },
    REMOVE_ITEM: {
        RECEIVE: null,
        REQUEST: null,
    },
    UPDATE_ITEM: {
        RECEIVE: null,
        REQUEST: null,
    },
};

const actionTypes = ['BEGIN_EDIT_ITEM', 'END_EDIT_ITEM', 'RESET', 'SET_CART_CONTENT', 'SET_SHIPPING_METHOD_UPDATED'];

export default createActions(actionMap, ...actionTypes, { prefix });
