'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import actions from '../store/actions/gtm';
import bindActionCreators from '../helpers/bindActionCreators';

const GTMContext = createContext();

const GTMContextProvider = ({ actions, children, gtmState, initState }) => {
    const contextValue = useMemo(() => [{ ...initState, ...gtmState }, { actions }], [actions, gtmState, initState]);

    return <GTMContext.Provider value={contextValue}>{children}</GTMContext.Provider>;
};

const mapStateToProps = ({ gtm }) => ({ gtmState: gtm });

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(GTMContextProvider);

export const useGtmContext = () => useContext(GTMContext);
