import { useCallback } from 'react';
import actionsTagManager from '../helpers/actions';
import { mapFredHopperValues } from '../helpers/flattenProductData';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';

interface IFilterValues {
    facetId?: string;
    facetValue?: string;
    locale: string;
    responseId?: string;
}

interface IUseSelectFilters {
    selectFilter: (values: IFilterValues) => void;
}

export const useSelectFilter = (): IUseSelectFilters => {
    const [{ isEnabled }] = useGtmContext();

    const selectFilter = useCallback(
        (fredHopperData: IFilterValues) => {
            if (!isEnabled || !fredHopperData?.facetId) return;

            actionsTagManager.selectFilter(mapFredHopperValues(fredHopperData));
        },
        [isEnabled],
    );

    return { selectFilter };
};
