import { useEffect } from 'react';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';

export const useBrowserAndStore = ({ isEnabled, storeCode, storeName, websiteCode }) => {
    useEffect(() => {
        if (!isEnabled) {
            return;
        }

        const { userAgent } = navigator;
        const browser = {};

        if (userAgent.includes('Firefox/')) {
            browser.name = 'Firefox';
            browser.version = userAgent.split('Firefox/')[1];
        } else if (userAgent.includes('Edg/')) {
            browser.name = 'Edge';
            browser.version = userAgent.split('Edg/')[1];
        } else if (userAgent.includes('OPR/')) {
            browser.name = 'Opera';
            browser.version = userAgent.split('OPR/')[1];
        } else if (userAgent.includes('Chrome/')) {
            browser.name = 'Chrome';
            browser.version = userAgent.split('Chrome/')[1]?.split(' ')[0] || 'Unknown';
        } else if (userAgent.includes('Safari/')) {
            browser.name = 'Safari';
            browser.version = userAgent.split('Version/')[1]?.split(' ')[0] || 'Unknown';
        } else {
            browser.name = 'Unknown';
            browser.version = 'Unknown';
        }

        const store = { country: websiteCode.toUpperCase(), language: storeName.toUpperCase(), store_code: storeCode };

        actionsTagManager.browserAndStore({ browser, store });
    }, [isEnabled, storeCode, storeName, websiteCode]);
};
