import { useEffect } from 'react';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';

export const useAddToWishlist = ({ isAddedToWishlist }) => {
    const [{ isEnabled }] = useGtmContext();

    useEffect(() => {
        if (!isEnabled || !isAddedToWishlist) return;

        return actionsTagManager.addToWishlist();
    }, [isAddedToWishlist, isEnabled]);
};
