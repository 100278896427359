'use client';

import { createContext, useContext } from 'react';
import { IContentValue, IRequestProviderProps } from '@/lib/context/request/types';

const RequestContext = createContext<IContentValue>({ storeCode: process.env.STORE_VIEW_CODE } as IContentValue);

export const useRequestContext = () => useContext(RequestContext);

export const RequestContextProvider = ({ children, req }: IRequestProviderProps) => (
    <RequestContext.Provider value={req}>{children}</RequestContext.Provider>
);

export default RequestContext;
