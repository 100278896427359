import TagManager from './tagManager';

export default {
    addPaymentInfo: ({ coupon, items, paymentMethod }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: {
                    ...(coupon?.length && { coupon }),
                    items,
                    payment_type: paymentMethod,
                },
                event: 'add_payment_info',
            },
        });
    },
    addPaymentInfoHeadless: () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'add_payment_info_headless',
            },
        });
    },
    addShippingInfo: ({ coupon, items, shippingMethod }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: {
                    ...(coupon?.length && { coupon }),
                    items,
                    shipping_tier: shippingMethod,
                },
                event: 'add_shipping_info',
            },
        });
    },
    addToCart: ({ items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { items },
                event: 'add_to_cart',
            },
        });
    },
    addToWishlist: () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'add_to_wishlist',
            },
        });
    },
    beginCheckout: ({ coupon, items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: {
                    ...(coupon?.length && { coupon }),
                    items,
                },
                event: 'begin_checkout',
            },
        });
    },
    browserAndStore: ({ browser, store }) => {
        TagManager.dataLayer({
            dataLayer: { browser, store },
        });
    },
    category: ({ path }) => {
        TagManager.dataLayer({
            dataLayer: {
                category: { path },
            },
        });
    },
    customer: ({ customer }) => {
        TagManager.dataLayer({
            dataLayer: { customer },
        });
    },
    faceBookUserData: ({ fb_user_data }) => {
        if (fb_user_data) {
            TagManager.dataLayer({
                dataLayer: { fb_user_data },
            });
        }
    },
    formSubmit: ({ formName }) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'form_submit',
                form_name: formName,
            },
        });
    },
    initialize: ({ auth, gtmId, preview, source }) => {
        TagManager.initialize({
            auth,
            gtmId,
            preview,
            source,
        });
    },
    login: () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'login',
                login_success: true,
            },
        });
    },
    pageInfo: ({ browser, customer, page, store }) => {
        TagManager.dataLayer({
            dataLayer: {
                browser,
                customer,
                event: 'page_info',
                page,
                store,
            },
        });
    },
    pageType: ({ abTest = 'original', pageType }) => {
        TagManager.dataLayer({
            dataLayer: {
                a_b_test: abTest,
                event: 'page_type',
                page_type: pageType,
            },
        });
    },
    purchase: ({ purchase }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { purchase },
                event: 'purchase',
            },
        });
    },
    removeFromCart: ({ items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { items },
                event: 'remove_from_cart',
            },
        });
    },
    resetEcommerce: () => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: null,
            },
        });
    },
    resetLogin: () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'reset_login_success',
                login_success: null,
            },
        });
    },
    reviewOrder: ({ coupon, items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: {
                    ...(coupon?.length && { coupon }),
                    items,
                },
                event: 'review_order',
            },
        });
    },
    selectFilter: (fredHopper) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'select_filter',
                fredHopper,
            },
        });
    },
    selectItem: ({ fredHopper, items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { items },
                event: 'select_item',
                fredHopper,
            },
        });
    },
    selectPromotion: ({ items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { items },
                event: 'select_promotion',
            },
        });
    },
    shippingAddressDetails: ({ coupon, items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: {
                    ...(coupon?.length && { coupon }),
                    items,
                },
                event: 'shipping_address_details',
            },
        });
    },
    signUp: () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'sign_up',
            },
        });
    },
    toggleClick: (state) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'filters_toggle_click',
                filter_interaction: state,
            },
        });
    },
    viewCart: ({ coupon, items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: {
                    ...(coupon?.length && { coupon }),
                    items,
                },
                event: 'view_cart',
            },
        });
    },
    viewItem: ({ items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { items },
                event: 'view_item',
            },
        });
    },
    viewItemList: ({ fredHopper, items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { items },
                event: 'view_item_list',
                fredHopper,
            },
        });
    },
    viewPromotion: ({ items }) => {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: { items },
                event: 'view_promotion',
            },
        });
    },
};
