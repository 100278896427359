import { useEffect, useState } from 'react';
import { usePrevious } from '@vaimo/google-tag-manager/src/hooks';
import { mapCoupons, mapProductForCartEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useViewCart = ({ appliedCoupons, items }) => {
    const [{ isEnabled, isPriceIncludesTax }] = useGtmContext();

    const prevItems = usePrevious(items);
    const [isViewCart, setViewCart] = useState(false);

    useEffect(() => {
        if (
            !isViewCart &&
            isEnabled &&
            Array.isArray(prevItems) &&
            Array.isArray(items) &&
            items.length &&
            (!prevItems.length || prevItems === items)
        ) {
            setViewCart(true);
        }
    }, [isEnabled, prevItems, items, setViewCart, isViewCart]);

    useEffect(() => {
        if (!isEnabled || !isViewCart) return;

        actionsTagManager.resetEcommerce();
        actionsTagManager.viewCart({
            ...(appliedCoupons?.length && {
                coupon: mapCoupons({ appliedCoupons }),
            }),
            items: items.map(mapProductForCartEvents(isPriceIncludesTax)),
        });
    }, [isEnabled, isPriceIncludesTax, isViewCart]);
};
