import { useEffect } from 'react';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useCategory = ({ categoryPath }) => {
    const [{ isEnabled }] = useGtmContext();
    const path = categoryPath?.map(({ text }) => text).join(' | ');

    useEffect(() => {
        if (isEnabled && path) {
            actionsTagManager.category({ path });
        }
    }, [path, isEnabled]);
};
