import { useEffect } from 'react';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';

export const useInitialize = ({ auth, gtmId, isEnabled, preview, source }) => {
    useEffect(() => {
        if (!isEnabled) {
            return;
        }

        actionsTagManager.initialize({ auth, gtmId, preview, source });
    }, [isEnabled, gtmId, auth, preview, source]);
};
