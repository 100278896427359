const DEFAULT_QUANTITY = 1;
const ITEM_CATEGORY_PREFIX = 'item_category';

const createItemVariant = ([color, size]) => `size:${size.value_label},color:${color.value_label}`;

const createItemCategoryList = ({ categories }) =>
    categories?.reduce((acc, category, idx) => {
        acc[`${ITEM_CATEGORY_PREFIX}${idx ? idx + 1 : ''}`] = category.name;

        return acc;
    }, {}) || {};

const getPriceWithOrWithoutTax = (prices, quantity, isPriceIncludesTax) =>
    isPriceIncludesTax
        ? (prices.row_total_including_tax.value - prices.total_item_discount.value) / quantity
        : prices.final_price.value;

const getDiscountDescription = (itemDiscountDescription) => {
    if (!itemDiscountDescription?.discount_type) {
        return {};
    }

    const { discount, discount_amount, discount_type } = itemDiscountDescription || {};

    return {
        ...(typeof discount === 'number' && { discount }),
        ...(typeof discount_amount === 'number' && { discount_amount }),
        discount_type,
    };
};

export const mapProductForCartEvents =
    (isPriceIncludesTax = false) =>
    ({
        configurable_options: configurableOptions,
        configured_variant: configuredVariant,
        prices,
        product,
        quantity,
    }) => ({
        currency: prices.row_total_including_tax.currency,
        ...createItemCategoryList({ categories: product.categories }),
        item_id: configuredVariant?.sku || product?.sku,
        item_name: product.name,
        ...(configurableOptions?.length ? { item_variant: createItemVariant(configurableOptions) } : {}),
        price: getPriceWithOrWithoutTax(prices, quantity, isPriceIncludesTax),
        quantity,
    });

export const mapProductForViewItemEvent = ({ categories, name, price, sku }) => ({
    currency: price.regularPrice.amount.currency,
    ...createItemCategoryList({ categories }),
    item_id: sku,
    item_name: name,
    price: price.regularPrice.amount.value / DEFAULT_QUANTITY,
    quantity: DEFAULT_QUANTITY,
});

export const mapProductForSelectItemEvent = ({ categories, name, price_range, sku }) => ({
    currency: price_range.minimum_price.regular_price.currency,
    ...createItemCategoryList({ categories }),
    item_id: sku,
    item_name: name,
    price: price_range.minimum_price.regular_price.value / DEFAULT_QUANTITY,
    quantity: DEFAULT_QUANTITY,
});

export const mapProductForViewItemListEvent = ({ categoryId, itemListName, items }) =>
    items.map(({ categories, name, price_range: price, sku }, id) => ({
        currency: price.minimum_price.regular_price.currency,
        index: id + 1,
        ...createItemCategoryList({ categories }),
        item_id: sku,
        item_list_id: categoryId?.toString(),
        item_list_name: itemListName ?? 'Category Page',
        item_name: name,
        price: price.minimum_price.regular_price.value / DEFAULT_QUANTITY,
        quantity: DEFAULT_QUANTITY,
    }));

export const mapProductForCheckoutEvents = ({
    configurable_options: configurableOptions,
    configured_variant: configuredVariant,
    item_discount_description: itemDiscountDescription,
    prices,
    product,
    quantity,
}) => ({
    currency: prices.final_price?.currency,
    ...createItemCategoryList({ categories: product.categories }),
    ...getDiscountDescription(itemDiscountDescription),
    item_id: configuredVariant?.sku || product?.sku,
    item_name: product.name,
    ...(configurableOptions?.length ? { item_variant: createItemVariant(configurableOptions) } : {}),
    price: prices.final_price?.value,
    quantity,
});

export const mapCoupons = ({ appliedCoupons }) => appliedCoupons?.map(({ code }) => code).join(',') || [];

export const mapFredHopperValues = ({
    facetId = '',
    facetValue = '',
    locale = '',
    responseId = '',
    searchTerms = '',
}) => {
    const data = {
        locale,
        searchTerms,
        sourceId: responseId,
        ...(facetId && {
            facetId,
            [facetId]: facetValue,
        }),
    };

    return Object.entries(data).reduce((obj, [key, value]) => {
        if (value) {
            obj[key] = value;
        }

        return obj;
    }, {});
};
