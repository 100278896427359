import { useEffect, useState } from 'react';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';
import { usePrevious } from '@vaimo/google-tag-manager/src/hooks';
import { mapProductForCartEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';

const getAddedProduct = (prevCart, currentCart) => {
    if (!prevCart || !currentCart) {
        return null;
    }

    const prevItems = prevCart.cart.items;
    const currentItems = currentCart.cart.items;

    for (let currentItem of currentItems) {
        const prevItem = prevItems.find((prevItem) => prevItem.uid === currentItem.uid);

        if (!prevItem) {
            return currentItem;
        }

        if (prevItem.quantity < currentItem.quantity) {
            return {
                ...currentItem,
                quantity: currentItem.quantity - prevItem.quantity,
            };
        }
    }
};

const getRemovedProduct = (prevCart, currentCart) => {
    if (!prevCart || !currentCart) {
        return null;
    }

    const prevItems = prevCart.cart.items;
    const currentItems = currentCart.cart.items;

    if (!currentItems.length) return prevItems[0];

    for (let prevItem of prevItems) {
        const currentItem = currentItems.find((currentItem) => prevItem.uid === currentItem.uid);

        if (!currentItem) {
            return prevItem;
        }

        if (prevItem.quantity > currentItem.quantity) {
            return {
                ...prevItem,
                quantity: prevItem.quantity - currentItem.quantity,
            };
        }
    }
};

export const useCartUpdate = ({ cartContent, cartId }) => {
    const [{ isPriceIncludesTax }] = useGtmContext();
    const [currentCart, setCurrentCart] = useState(null);
    const [addedToCartProduct, setAddedToCartProduct] = useState(null);
    const [removedFromCartProduct, setRemovedFromCartProduct] = useState(null);
    const prevCartId = usePrevious(cartId);
    const previousCart = usePrevious(currentCart);
    const [isMergingCart, setIsMergingCart] = useState(false);

    useEffect(() => {
        if (!prevCartId) return;

        if (!cartId) {
            if (!isMergingCart) {
                setIsMergingCart(true);
            }
        } else {
            if (isMergingCart && cartId === prevCartId) {
                setIsMergingCart(false);
            }
        }
    }, [prevCartId, cartId, isMergingCart]);

    useEffect(() => {
        if (!cartContent) return;

        setCurrentCart(cartContent);
    }, [cartContent]);

    useEffect(() => {
        if (isMergingCart || (currentCart?.cart && previousCart?.cart?.id !== currentCart?.cart.id)) return;

        const addedProduct = getAddedProduct(previousCart, currentCart);
        const removedProduct = getRemovedProduct(previousCart, currentCart);

        if (addedProduct) {
            setAddedToCartProduct(addedProduct);
        } else if (removedProduct) {
            setRemovedFromCartProduct(removedProduct);
        }
    }, [currentCart, previousCart, isMergingCart]);

    useEffect(() => {
        if (addedToCartProduct) {
            actionsTagManager.resetEcommerce();
            actionsTagManager.addToCart({
                items: [addedToCartProduct].map(mapProductForCartEvents(isPriceIncludesTax)),
            });
        }
    }, [addedToCartProduct, isPriceIncludesTax]);

    useEffect(() => {
        if (removedFromCartProduct) {
            actionsTagManager.resetEcommerce();
            actionsTagManager.removeFromCart({
                items: [removedFromCartProduct].map(mapProductForCartEvents(isPriceIncludesTax)),
            });
        }
    }, [isPriceIncludesTax, removedFromCartProduct]);
};
