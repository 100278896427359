export const mapPromotionsData = ({ promotions }) =>
    promotions?.map((promotion) => {
        const [, promotion_name, promotion_id, creative_name, location_id] =
            promotion.match(/promo_name=(.*)&promo_id=(.*)&promo_creative=(.*)&promo_position=(\d[a-z])/) || [];

        return {
            creative_name,
            creative_slot: '',
            location_id,
            promotion_id,
            promotion_name,
        };
    }) || [];
