import { BrowserPersistence } from '@/utils';
import { removeCart } from '@/lib/store/actions/cart';
import { clearCartDataFromCache } from '@/lib/store/utils/clearCartDataFromCache';
import { clearCustomerDataFromCache } from '@/lib/store/utils/clearCustomerDataFromCache';
import actions from './actions';

import { USER_STORAGE } from '@/constants/storage';

const storage = new BrowserPersistence();

export const signOut = (payload = {}) =>
    async function thunk(dispatch, { apolloClient }) {
        const { revokeToken } = payload;

        if (revokeToken) {
            // Send mutation to revoke token.
            try {
                await revokeToken();
            } catch (error) {
                console.error('Error Revoking Token', error);
            }
        }

        // Remove token from local storage and Redux.
        await dispatch(clearToken());
        await dispatch(actions.reset());
        apolloClient && (await clearCartDataFromCache(apolloClient));
        apolloClient && (await clearCustomerDataFromCache(apolloClient));

        // Remove session data related flag
        storage.setItem(USER_STORAGE.HAS_SESSION_DATA, false);

        // Now that we're signed out, forget the old (customer) cart.
        // We don't need to create a new cart here because we're going to refresh
        // the page immediately after.
        await dispatch(removeCart());
    };

export const getUserDetails = ({ fetchUserDetails }) =>
    async function thunk(...args) {
        const [dispatch, getState] = args;
        const { user } = getState();

        if (user.isSignedIn) {
            dispatch(actions.getDetails.request());

            try {
                const { data } = await fetchUserDetails({});

                dispatch(actions.getDetails.receive(data.customer));
            } catch (error) {
                dispatch(actions.getDetails.receive(error));
            }
        }
    };

export const resetPassword = ({ email }) =>
    async function thunk(...args) {
        const [dispatch] = args;

        dispatch(actions.resetPassword.request());

        // TODO: actually make the call to the API.
        // For now, just return a resolved promise.
        await Promise.resolve(email);

        dispatch(actions.resetPassword.receive());
    };

export const setToken = ({ customerSigninTokenLifetimeInHours = 14 * 24, token }) =>
    async function thunk(...args) {
        const [dispatch] = args;

        // Store token in local storage.
        storage.setItem(USER_STORAGE.SIGNIN_TOKEN, token, customerSigninTokenLifetimeInHours * 60 * 60);

        // Persist in store
        dispatch(actions.setToken(token));
    };

export const clearToken = () =>
    async function thunk(...args) {
        const [dispatch] = args;

        // Clear token from local storage
        storage.removeItem(USER_STORAGE.SIGNIN_TOKEN);
        storage.removeItem(USER_STORAGE.HAS_SESSION_DATA);

        // Remove from store
        dispatch(actions.clearToken());
    };
