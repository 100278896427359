'use client';

export interface IUserAgent {
    isBot: boolean;
    isPerformanceTracker: boolean;
}

const GOOGLE_LIGHTHOUSE_AGENTS = ['chrome-lighthouse', 'google page speed insights'];
const GOOGLE_SEARCH_AGENTS = ['googlebot'];
const GTMETRIX_AGENTS = ['gtmetrix'];
const PRERENDER_AGENTS = ['prerender'];

const useUserAgent = (): IUserAgent => {
    const isClient = globalThis.navigator && typeof globalThis.navigator.userAgent === 'string';
    if (!isClient) {
        return {
            isBot: false,
            isPerformanceTracker: false,
        };
    }

    const userAgent = globalThis.navigator.userAgent.toLowerCase();

    const doesUserAgentMatch = (agentsList: string[]): boolean => agentsList.some((agent) => userAgent.includes(agent));

    const isBot = doesUserAgentMatch([...GOOGLE_SEARCH_AGENTS, ...PRERENDER_AGENTS]);
    const isPerformanceTracker = doesUserAgentMatch([
        ...GOOGLE_LIGHTHOUSE_AGENTS,
        ...GOOGLE_SEARCH_AGENTS,
        ...GTMETRIX_AGENTS,
    ]);

    return {
        isBot,
        isPerformanceTracker,
    };
};

export default useUserAgent;
