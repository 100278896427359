'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import actions from '@/lib/store/actions/app/actions';
import * as asyncActions from '@/lib/store/actions/app/asyncActions';
import bindActionCreators from '@/lib/store/utils/bindActionCreators';

const AppContext = createContext();

const AppContextProvider = (props) => {
    const { actions, appState, asyncActions, children, initState } = props;

    const contextValue = useMemo(
        () => [
            { ...initState, ...appState },
            {
                actions,
                ...asyncActions,
            },
        ],
        [actions, appState, asyncActions, initState],
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

const mapStateToProps = ({ app }) => ({ appState: app });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    asyncActions: bindActionCreators(asyncActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContextProvider);

export const useAppContext = () => useContext(AppContext);
